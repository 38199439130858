/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * adelle:
 *   - http://typekit.com/eulas/00000000000000003b9af921
 * neuzeit-grotesk:
 *   - http://typekit.com/eulas/0000000000000000000178a6
 *   - http://typekit.com/eulas/0000000000000000000178a7
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-02-19 15:34:20 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=jvl1fug&ht=tk&f=6864.12102.12103&a=1387100&app=typekit&e=css");

@font-face {
font-family:"adelle";
src:url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;
}

@font-face {
font-family:"neuzeit-grotesk";
src:url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/cde90e/0000000000000000000178a6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"neuzeit-grotesk";
src:url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/d7bb7b/0000000000000000000178a7/27/a?subset_id=2&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

.tk-adelle { font-family: "adelle",serif; }
.tk-neuzeit-grotesk { font-family: "neuzeit-grotesk",sans-serif; }
@import 'vars';
@import 'general';