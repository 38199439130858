h1,h3{
    font-family: adelle, serif !important;
    font-weight: 600;
    font-style: normal;

}
p{
    font-family: neuzeit-grotesk, sans-serif !important;
    font-weight: 300;
    font-style: normal;
    line-height: 1.4em;
}
a{
    font-family: neuzeit-grotesk, sans-serif !important;
    font-weight: 300;
    font-style: normal;
    color:$grey;
}

html, body{
    margin: 0;
    @media all and (max-width:1000px){
            height: 100%
    }
}
.site{
    background-color: $background;
    min-height: 100%;

}
.header{
    border-top: solid;
    border-width: 5px;
    border-color: $darkgrey;
}
.logo{
    display:flex;
    margin: auto;
    width: 200px;
    padding-top: 92px;
    padding-bottom: 92px;
    @media all and (max-width:1000px){
        margin-bottom: 15px;
        padding-bottom: 50px;
    }

}
.wrapper{
    max-width: 1090px;
    margin: auto;
    @media all and (max-width:1000px){
        max-width: 340px;
    }
}
.info-icon{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 69px;
    .info-card{
            width: 33.33%;
            @media all and (max-width:1000px){
                width: 100%;
                padding-bottom: 50px;
            }
        img{
            display: flex;
            margin: auto;
            width: 50px;
        }
        p{
            display: flex;
            text-align: center;
            justify-content: center;
            color: $grey;
            line-height: 1.5em;
            margin-top:10px;
        }
        h1{
            justify-content: center;
            display: flex;
            margin: auto;
            text-align: center;
            font-weight: 500;
            font-size: 2.2em;
            color: $darkgrey;
            padding-top: 20px;
        }   
    }
    .flex-order-one{
        order: 0;
    }
    .flex-order-two{
        order: 0;
    }
    .flex-order-three{
        order: 0;
    }
    @media all and (max-width:1000px){
        .flex-order-one{
            order: 1;
        }
        .flex-order-two{
            order: 2;
        }
        .flex-order-three{
            order: 3;
            padding-bottom:10px;
        }
    }
}

.contact{
    display: flex;
    outline: solid;
    outline-width: thin;
    outline-color: $outfoxTan;
    width: 100%;
    height: 165px;
    justify-content: center;
    line-height: 1.5em;

    
    .contact-info{
        background-color: $background;
        outline: solid;
        outline-width: thin;
        outline-color: $outfoxTan;
        width: 450px;
        justify-content: center;
        h3{
            text-align: center;
            background-color: #fff;
            outline: solid;
            outline-width: thin;
            outline-color: $outfoxTan;
            margin: auto;
            font-weight: 200;
            padding-top: 10px;
            padding-bottom: 10px;
            letter-spacing: 0.1em;
        }
        .link-box{
            background-color: $background;
            padding-top:20px;
        }
        .top-link{
            padding-top: 5px;
        }
        .contact-link{
            text-align: center;
            
            p{
                display: inline-flex;
                font-weight: 600;
                font-size: small;
                padding-right: 5px;
                padding-bottom: 0px;
                font-size: .8em;
                line-height: 1.7em;
                letter-spacing: .01em;
                margin-bottom: 0px;
                margin-top:0px;
            }
            a{
                text-decoration: none;
                font-size: 1.0em;
                padding-bottom: 0px
            }
        }
    }

    

}

footer{
    background-color: $background;
    .back-img{
    background-image: url('/source/img/outfox-pattern.png');
    background-size: 2000px;
    background-repeat: round;
    height: 150px;
    @media all and (max-width:1000px){
        background-repeat: repeat;
    }
}

}